import styled from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";

export const HomeWrapper = styled.div`
  --hs-vertical-margin: 24px;

  @media (min-width: ${breakpoint.tablet}) {
    --hs-vertical-margin: 40px;
  }

  margin-bottom: 40px;
`;
