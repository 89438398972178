import React from "react";
import escape from "lodash/escape";

import { useTranslation } from "common/i18n";
import { NewIcon } from "components/NewIcon";

import { IconBox, Item, ItemDescription, ItemTitle, List, Root, Title } from "./styled";

const Onboarding = () => {
  const { t } = useTranslation("common");

  const items = [
    {
      icon: "headphones-alt" as const,
      title: t("home.onboarding.item1.title"),
      description: t("home.onboarding.item1.description"),
    },
    {
      icon: "mobile-phone" as const,
      title: t("home.onboarding.item2.title"),
      description: t("home.onboarding.item2.description"),
    },
    {
      icon: "hand-with-hearts" as const,
      title: t("home.onboarding.item3.title"),
      description: t("home.onboarding.item3.description"),
    },
    {
      icon: "fire" as const,
      title: t("home.onboarding.item4.title"),
      description: t("home.onboarding.item4.description"),
      link: t("home.onboarding.item4.link"),
    },
  ];

  return (
    <Root>
      <Title>{t("home.onboarding.title")}</Title>
      <List>
        {items.map(({ icon, title, description, link }) => (
          <Item key={icon}>
            <IconBox>
              <NewIcon icon={icon} width="24" height="24" />
            </IconBox>
            <ItemTitle
              dangerouslySetInnerHTML={{
                __html: escape(title).replace(
                  /\[\[(.*?)]]/g,
                  link ? `<a class="pink" href="${link}" target="_blank">$1</a>` : `<span class="pink">$1</span>`
                ),
              }}
            />
            <ItemDescription>{description}</ItemDescription>
          </Item>
        ))}
      </List>
    </Root>
  );
};

export default Onboarding;
