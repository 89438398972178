import React from "react";
import styled from "styled-components";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { BannerCarouselSection } from "resources/AudiotekaApi";
import Link from "components/link";
import { Breakpoint } from "modules/DesignSystem/breakpoint";

const Container = styled(Link)`
  min-width: 0;
  flex: 0 0 var(--slide-size);
  margin-left: var(--slide-spacing);
  border-radius: 6px;
  overflow: hidden;
`;

const Picture = styled.picture`
  display: flex;
  transition: transform 1s ease-in-out;
  width: 100%;

  > img {
    aspect-ratio: 16/9;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }
`;

type SlideProps = {
  element: BannerCarouselSection["elements"][number];
  lazyLoading: boolean;
  onClick: () => void;
};

export const Slide = ({ element, lazyLoading, onClick }: SlideProps) => {
  return (
    <Container
      {...deeplinkTranslator(element.deeplink, element.slug)}
      onClick={onClick}
      className="embla__slide"
      title={element.accessibility_label}
    >
      <Picture className="embla__slide-image">
        <source media={`(min-width: ${Breakpoint.sm}px)`} srcSet={`${element.banner_url}?w=530&auto=format`} />
        <img
          loading={lazyLoading ? "lazy" : null}
          alt={element.accessibility_label}
          src={`${element.banner_url}?w=480&auto=format`}
        />
      </Picture>
    </Container>
  );
};
