import React from "react";
import styled, { css } from "styled-components";

import Link, { LinkParams } from "components/link";
import shouldBeADarkColor from "utils/shouldBeADarkColor";
import { Breakpoint } from "modules/DesignSystem/breakpoint";

const Wrapper = styled.div<{ isMobileReveal: boolean }>`
  width: 100%;
  margin: 0 12px 16px 12px;

  @media (min-width: ${Breakpoint.md}px) {
    width: calc(((1 / 2) * 100%) - 24px);
  }

  @media (min-width: ${Breakpoint.lg}px) {
    width: calc(((1 / 3) * 100%) - 24px);
  }

  ${({ isMobileReveal }) =>
    !isMobileReveal &&
    css`
      &:nth-child(n + 4) {
        display: none;
      }

      @media (min-width: ${Breakpoint.md}px) {
        display: block !important;
      }
    `};

  overflow: hidden;
  position: relative;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
    transition: transform 1s ease-in-out;
    transform-origin: center center;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const Label = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${(props) => `#${props.color}`};
  font-size: 16px;
  color: ${({ color }) => (shouldBeADarkColor(color) ? "black" : "white")};
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  padding: 5px 10px;

  span {
    font-size: 12px;
    margin-left: 10px;
  }
`;

type TeaserGroupProps = {
  isMobileReveal: boolean;
  label: string;
  bannerUrl: string;
  itemCount?: string;
  labelColor: string;
  link: LinkParams;
  accessibilityLabel: string;
};

const TeaserGroup = ({
  isMobileReveal,
  label,
  bannerUrl,
  itemCount,
  labelColor,
  link,
  accessibilityLabel,
}: TeaserGroupProps) => (
  <Wrapper isMobileReveal={isMobileReveal}>
    <Link route={link.route} params={link.params} href={link.href} title={accessibilityLabel}>
      <img loading="lazy" src={`${bannerUrl}?h=174`} alt={accessibilityLabel} />
      <Label color={labelColor || "eeebf2"}>
        {label} {itemCount && <span>{itemCount}</span>}
      </Label>
    </Link>
  </Wrapper>
);

export default TeaserGroup;
