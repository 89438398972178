import React, { useEffect, useMemo, useRef } from "react";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { WebHeroSection } from "resources/AudiotekaApi";

import { useHome } from "../../home.hooks";
import { getGTMElement } from "../../home.utils";
import { Banner, BannerContainer, Button, Container, Content, Description, Root, Title } from "./styled";

export const WebHero = ({ section }: { section: WebHeroSection }) => {
  const {
    elements: [element],
  } = section;

  const rootRef = useRef<HTMLDivElement>();

  const home = useHome();

  const gtmData = useMemo(() => getGTMElement(section), [section]);

  useEffect(() => {
    const { current: root } = rootRef;

    if (!root) {
      return () => undefined;
    }

    home.observe(root, gtmData);

    return () => {
      home.unobserve(root);
    };
  }, [gtmData, rootRef.current]);

  return (
    <Root ref={rootRef} style={{ backgroundImage: `url(${element.background_image_url}?auto=format)` }}>
      <Container>
        <Content>
          <Title color={element.title_color}>{element.title}</Title>
          <Description color={element.description_color}>{element.description}</Description>
          <Button
            bgColor={element.button_color}
            color={element.button_label_color}
            onClick={() => home.sendEvent("select", gtmData)}
            {...deeplinkTranslator(element.button_deeplink)}
          >
            {element.button_label}
          </Button>
        </Content>
        <BannerContainer>
          <Banner
            srcSet={`${element.banner_image_url}?w=500&auto=format 400w, ${element.banner_image_url}?w=900&auto=format 700w`}
          />
        </BannerContainer>
      </Container>
    </Root>
  );
};
