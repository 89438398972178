import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { BannerCarouselSection } from "resources/AudiotekaApi";

import { useHome } from "../../home.hooks";
import { getGTMElement, trackCTAHomeEvent } from "../../home.utils";
import { Embla, EmblaViewport, EmblaSlides } from "./BannerCarousel.styled";
import { Slide } from "./Slide";
import { DotButtons } from "./DotButtons/DotButtons";
import { ArrowButtons } from "./ArrowButtons/ArrowButtons";

export const BannerCarousel = ({ lazyLoading, section }: { lazyLoading: boolean; section: BannerCarouselSection }) => {
  const home = useHome();
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, align: () => 16 }, [
    Autoplay({ stopOnInteraction: false, stopOnMouseEnter: true }),
  ]);

  const { elements } = section;

  return (
    <Embla className="banner-carousel">
      <EmblaViewport ref={emblaRef}>
        <EmblaSlides>
          {elements.map((element, index) => (
            <Slide
              key={element.tracking_id}
              lazyLoading={lazyLoading}
              element={element}
              onClick={() => {
                home.sendEvent("select", getGTMElement(section, index));
                trackCTAHomeEvent("banner-carousel", element.accessibility_label);
              }}
            />
          ))}
        </EmblaSlides>
        <ArrowButtons emblaApi={emblaApi} />
      </EmblaViewport>
      <DotButtons emblaApi={emblaApi} />
    </Embla>
  );
};
