import styled from "styled-components";

import ButtonBase from "modules/DesignSystem/components/Button";
import ContainerBase from "modules/DesignSystem/components/Container";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

export const Banner = styled.img`
  height: 100%;
  left: 0;
  object-fit: contain;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const BannerContainer = styled.div`
  flex: 10;
  margin: 0 auto;
  max-width: 696px;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
    padding-top: 81.609%;
  }
`;

export const Button = styled(ButtonBase)<{ bgColor: string; color: string }>`
  background: ${({ bgColor }) => (bgColor.startsWith("#") ? bgColor : `#${bgColor}`)} !important;
  color: ${({ color }) => (color.startsWith("#") ? color : `#${color}`)};
  font-size: 16px !important;
  line-height: 24px;
  margin: 16px 0 0;
  padding: 15px 32px;
  width: 100%;

  @media screen and ${MEDIA_QUERY.lg} {
    width: auto;
  }
`;

export const Container = styled(ContainerBase)`
  align-items: stretch;
  column-gap: 48px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media screen and ${MEDIA_QUERY.lg} {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 9;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 0;

  @media screen and ${MEDIA_QUERY.lg} {
    padding-bottom: 24px;
  }
`;

export const Description = styled.p<{ color: string }>`
  color: ${({ color }) => (color.startsWith("#") ? color : `#${color}`)};
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  margin: 16px 0;

  @media screen and ${MEDIA_QUERY.xl} {
    font-size: 24px;
  }
`;

export const Root = styled.div`
  background-position: center;
  background-size: cover;
  margin: 32px 0;

  &:first-child {
    margin: 0 0 16px;

    + .banner-carousel {
      margin-top: 16px;
    }
  }

  @media screen and ${MEDIA_QUERY.md} {
    margin: 80px 0;
  }
`;

export const Title = styled.p<{ color: string }>`
  color: ${({ color }) => (color.startsWith("#") ? color : `#${color}`)};
  font-size: 32px;
  font-weight: 700;
  line-height: 120%;
  margin: 16px 0;

  @media screen and ${MEDIA_QUERY.xl} {
    font-size: 48px;
  }

  @media screen and ${MEDIA_QUERY.xxl} {
    font-size: 56px;
  }
`;
