import React from "react";

import { PlaceholderSection } from "resources/AudiotekaApi/AudiotekaApi.types";

import { AboutClub, Faq, Onboarding, PageTitle } from "./components";

export const Placeholder = ({ section }: { section: PlaceholderSection }) => {
  const {
    elements: [{ placeholder_reference: name }],
  } = section;

  switch (name) {
    case "about-club":
      return <AboutClub />;
    case "faq":
      return <Faq />;
    case "onboarding":
      return <Onboarding />;
    case "page-title":
      return <PageTitle />;
    default:
      return null;
  }
};
