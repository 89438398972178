import styled from "styled-components";
import { Breakpoint } from "modules/DesignSystem/breakpoint";

export const Embla = styled.div`
  --slide-spacing: 16px;
  --slide-size: 85%;

  @media (min-width: ${Breakpoint.sm}px) {
    --slide-size: 500px;
  }

  @media (min-width: ${Breakpoint.md}px) {
    --slide-size: 530px;
  }

  --arrow-buttons-visibility: hidden;
  --arrow-buttons-opacity: 0;
  &:hover {
    --arrow-buttons-visibility: visible;
    --arrow-buttons-opacity: 1;
  }

  margin-top: var(--hs-vertical-margin);

  & + div {
    margin-top: 18px;
  }
`;

export const EmblaViewport = styled.div`
  position: relative;
  overflow: hidden;
`;

export const EmblaSlides = styled.div`
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
`;
