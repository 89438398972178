import styled from "styled-components";

import { breakpoint } from "modules/DesignSystem/breakpoint";
import Container from "modules/DesignSystem/components/Container";
import Button from "modules/DesignSystem/components/Button";
import TeaserListBase from "modules/DesignSystem/components/TeaserList";

export const Action = styled(Button)`
  line-height: 20px;
  padding: 10px 24px;
  transition: all 0.2s ease;
  width: fit-content;

  &:hover {
    background-color: #d518ff !important;
    color: #fff !important;
  }

  @media (min-width: ${breakpoint.tablet}) {
    max-width: 300px;
    padding: 14px 32px;
    width: 100%;
  }
`;

export const Content = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 518px;
  padding-bottom: 26px;
  padding-top: 26px;

  > ${Container} {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: ${breakpoint.tablet}) {
    min-height: 547px;
    padding-bottom: 36px;
    padding-top: 36px;
  }
`;

export const Description = styled.div`
  font-size: 14px;
  max-width: calc(100% - 150px); // 150px to not get covered background completely by description

  > p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 16px;
    max-width: 100%;
  }
`;

export const TeaserList = styled(TeaserListBase)`
  margin-bottom: 15px;

  @media (min-width: ${breakpoint.tablet}) {
    flex: 1;
  }
`;

export const Root = styled.div`
  position: relative;
  margin: var(--hs-vertical-margin) auto;
`;

export const BgPicture = styled.picture`
  position: absolute;
  inset: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;

    @media (min-width: ${breakpoint.tablet}) {
      object-position: center;
    }
  }
`;
