import { withServerTranslations } from "common/i18n/withServerTranslations";
import { withoutDefaultLocale } from "common/i18n/withoutDefaultLocale";
import { getHomeScreen } from "resources/AudiotekaApi";
import { getLocationInfo } from "utils/getLocationInfo";

export { default } from "modules/Home";

export const getStaticProps = withoutDefaultLocale(
  withServerTranslations(["common"])(async ({ locale }) => {
    const { catalogId } = getLocationInfo(locale);

    const initialHomeScreen = await getHomeScreen(catalogId);

    return {
      props: { initialHomeScreen },
      revalidate: 3600,
    };
  })
);
