import React from "react";
import styled from "styled-components";

import { useTranslation } from "common/i18n";
import { breakpoint } from "modules/DesignSystem/breakpoint";

const Heading = styled.h1`
  font-size: 20px;
  line-height: 24px;
  margin: var(--hs-vertical-margin) 16px;
  text-align: center;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 32px;
    line-height: 44px;
  }
`;

export function PageTitle() {
  const { t } = useTranslation();

  return <Heading>{t("home.title")}</Heading>;
}
