import React, { useMemo } from "react";
import { useTranslation } from "common/i18n";

import Link from "components/link";
import { Accordion } from "components/Accordion";

import { Description, List, Root, Title } from "./Faq.styled";

const Faq: React.FC = () => {
  const { t } = useTranslation("common");

  const FAQs = useMemo(() => {
    const list = [];

    let questionKey: string;
    let answerKey: string;
    let question: string;
    let answer: string;
    let index = 0;

    do {
      index += 1;

      questionKey = `home.faq.question${index}`;
      answerKey = `home.faq.answer${index}`;

      question = t(questionKey);
      answer = t(answerKey);

      if (question !== questionKey && answer !== answerKey) {
        list.push({ question, answer });
      }
    } while ((question !== questionKey && answer !== answerKey) || index < 50);

    return list;
  }, []);

  return (
    <Root>
      <Title>{t("home.faq.title")}</Title>
      <Description>
        {t("home.faq.description.text")}
        <Link route="helpCenter">{t("home.faq.description.link_label")}</Link>
      </Description>
      <List>
        {FAQs.map(({ question, answer }, index) => (
          <Accordion key={question} id={`question-${index}`} title={question}>
            {answer}
          </Accordion>
        ))}
      </List>
    </Root>
  );
};

export default Faq;
