import React, { PropsWithChildren, ReactNode } from "react";

import Markdown from "components/markdown";
import { Container, Trigger, Title, ExpandIcon, AnimationWrapper, TransformWrapper, Content } from "./Accordion.styled";

interface Props {
  id: string;
  defaultOpen?: boolean;
  title: ReactNode;
}

export const Accordion = ({ id, defaultOpen, title, children }: PropsWithChildren<Props>) => {
  const textContent = typeof children === "string" ? <Markdown text={children} /> : children;

  return (
    <Container>
      <Trigger type="checkbox" defaultChecked={defaultOpen} id={id} />
      <Title htmlFor={id}>
        {title}
        <ExpandIcon name="chevron-down" size={24} />
      </Title>
      <AnimationWrapper>
        <TransformWrapper>
          <Content>{textContent}</Content>
        </TransformWrapper>
      </AnimationWrapper>
    </Container>
  );
};
