import NextImage from "next/image";
import styled from "styled-components";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";

import { Link } from "components";
import Container from "modules/DesignSystem/components/Container";

export const Content = styled(Container)`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr;
  justify-items: center;
  padding-bottom: 30px;
  padding-top: 30px;

  @media ${MEDIA_QUERY.lg} {
    align-items: center;
    grid-template-columns: 1fr 350px 1fr;
  }

  @media ${MEDIA_QUERY.xl} {
    grid-template-columns: 1fr 450px 1fr;
  }
`;

export const BgPicture = styled.picture`
  position: absolute;
  inset: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #110f2f;
  }
`;

export const Image = styled(NextImage)`
  max-width: 300px;
  width: 100%;
  height: auto;

  @media ${MEDIA_QUERY.lg} {
    grid-column: 2;
    max-width: 100%;
  }
`;

export const IconWrapper = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  height: var(--icon-size);
  width: var(--icon-size);

  svg {
    height: var(--svg-size);
    width: var(--svg-size);
  }

  @media ${MEDIA_QUERY.xl} {
    border-radius: 16px;
  }
`;

export const Item = styled.li`
  --icon-size: 40px;
  --svg-size: 20px;
  align-items: center;
  display: grid;
  grid-template-columns: var(--icon-size) 1fr;
  font-size: 14px;
  font-weight: 700;
  gap: 12px;
  line-height: 18px;
  margin: 0;
  padding: 0;

  b,
  strong {
    color: #d518ff;
    font-weight: inherit;
  }

  p {
    margin: 0;
  }

  @media ${MEDIA_QUERY.xl} {
    --icon-size: 64px;
    --svg-size: 40px;
    font-size: 15px;
    gap: 24px;
    line-height: 140%;
  }

  @media ${MEDIA_QUERY.xxl} {
    font-size: 18px;
  }
`;

export const List = styled.ul<{ first?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 22px;
  list-style: none;
  margin: 0;
  max-width: 300px;
  padding: 0;
  width: 100%;

  @media ${MEDIA_QUERY.lg} {
    grid-row: 2;
    grid-column: ${({ first }) => (first ? 1 : 3)};
    justify-self: ${({ first }) => (first ? "end" : "start")};
  }

  @media ${MEDIA_QUERY.xl} {
    grid-gap: 40px;
    max-width: 380px;
  }
`;

export const MoreBtn = styled(Link)`
  background: #d518ff;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  max-width: 300px;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @media ${MEDIA_QUERY.lg} {
    grid-column: 1/4;
  }
`;

export const Root = styled.div`
  color: #fff;
  margin: var(--hs-vertical-margin) 0;
  position: relative;
`;

export const Title = styled.h3`
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  text-align: center;

  @media ${MEDIA_QUERY.lg} {
    grid-column: 1/4;
  }

  @media ${MEDIA_QUERY.xl} {
    font-size: 48px;
  }
`;
