import React from "react";
import styled from "styled-components";
import type { EmblaCarouselType } from "embla-carousel";

import { DotButton } from "./DotButton";
import { useDotButton } from "./useDotButton";

const Container = styled.div`
  --dot-size: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: var(--dot-size);
  margin-top: 16px;
`;

export const DotButtons = ({ emblaApi }: { emblaApi: EmblaCarouselType | undefined }) => {
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  if (scrollSnaps.length === 0) {
    // empty container used to avoid layout shift
    return <Container />;
  }

  return (
    <Container>
      {scrollSnaps.map((_, index) => (
        <DotButton
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onClick={() => onDotButtonClick(index)}
          selected={index === selectedIndex}
        />
      ))}
    </Container>
  );
};
