import React from "react";
import styled from "styled-components";

const Button = styled.button<{ selected: boolean }>`
  appearance: none;
  background-color: ${({ selected }) => (selected ? "#00ea9c" : "#dfdae6")};
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;
`;

type DotButtonProps = {
  selected: boolean;
  onClick: () => void;
  children?: React.ReactNode;
};

export const DotButton = (props: DotButtonProps) => {
  const { selected, onClick, children } = props;

  return (
    <Button type="button" onClick={onClick} selected={selected}>
      {children}
    </Button>
  );
};
