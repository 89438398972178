import React from "react";
import styled from "styled-components";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { breakpoint } from "modules/DesignSystem/breakpoint";
import { Link } from "components/link";

interface PromoSideButton {
  color: string | null;
  deeplink: string;
  label: string;
}

const SideButton = styled(Link)`
  font-weight: 700;
  text-decoration: none;
`;

const Title = styled.h2`
  flex: 1;
  font-size: 20px;
  margin: 0;

  @media (min-width: ${breakpoint.tablet}) {
    font-size: 32px;
  }
`;

const Top = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
`;

export const PromoTop = ({
  color,
  sideButton,
  title,
}: {
  color: string;
  sideButton: PromoSideButton | null;
  title: string;
}) => {
  return (
    <Top>
      <Title style={{ color: color ? `#${color}` : null }}>{title}</Title>
      {sideButton && (
        <SideButton {...deeplinkTranslator(sideButton.deeplink)} style={{ color: sideButton.color || "inherit" }}>
          {sideButton.label} &gt;
        </SideButton>
      )}
    </Top>
  );
};
