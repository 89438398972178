import styled from "styled-components";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import Container from "modules/DesignSystem/components/Container";

export const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 24px;
  text-align: center;
  white-space: pre-wrap;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const List = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;

  @media screen and ${MEDIA_QUERY.md} {
    gap: 16px;
  }
`;

export const Root = styled(Container)`
  margin: var(--hs-vertical-margin) auto;
  max-width: 1200px;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 8px;
  text-align: center;
`;
