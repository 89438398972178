import React from "react";
import styled from "styled-components";
import type { EmblaCarouselType } from "embla-carousel";

import { Breakpoint } from "modules/DesignSystem/breakpoint";

import { ArrowButton } from "./ArrowButton";
import { usePrevNextButtons } from "./usePrevNextButtons";

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  right: 16px;
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  display: none;

  @media (min-width: ${Breakpoint.md}px) {
    display: flex;
  }
`;

export const ArrowButtons = ({ emblaApi }: { emblaApi: EmblaCarouselType | undefined }) => {
  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  return (
    <Container className="embla__arrows">
      <ArrowButton direction="previous" onClick={onPrevButtonClick} />
      <ArrowButton direction="next" onClick={onNextButtonClick} />
    </Container>
  );
};
